@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles for the PrismFinancial application */

/* Base styles */
body {
  background-color: #09090b;
  color: #f8fafc;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Custom component classes */
@layer components {
  .feature-card {
    @apply bg-zinc-900/50 rounded-2xl ring-1 ring-zinc-100/10 h-[480px] overflow-hidden flex flex-col;
  }
  
  .card-content {
    @apply space-y-4 mt-auto px-8 pb-8 w-full;
  }
  
  .card-title {
    @apply font-medium text-lg/none text-zinc-200;
  }
  
  .card-description {
    @apply max-w-sm text-sm text-zinc-400/80;
  }
}

/* Additional custom styles */
/* Hero Section Styles */
.hero-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  position: relative;
  isolation: isolate;
}

.headline {
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(to bottom right, white, #71717a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
}

/* Metallic text effect */
.metallic-text {
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #e0e0e0 30%,
    #ffffff 45%,
    #a1a1aa 55%,
    #e0e0e0 70%,
    #ffffff 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
  position: relative;
  z-index: 1;
}

.metallic-text::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.3) 30%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0.2) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  filter: blur(4px);
}

.tagline {
  font-size: 1.25rem;
  color: #94a3b8;
  max-width: 800px;
  margin: 0 auto 2.5rem;
}

.cta-button {
  display: inline-block;
  position: relative;
  background-color: #09090b;
  color: #94a3b8;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.cta-button:hover {
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(56, 189, 248, 0.3);
}

.cta-button::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 10%;
  width: 80%;
  height: 1px;
  background: linear-gradient(to right, rgba(56, 189, 248, 0), rgba(56, 189, 248, 0.9), rgba(56, 189, 248, 0));
  opacity: 0;
  transition: opacity 0.3s;
}

.cta-button:hover::before {
  opacity: 0.4;
}

/* Dashboard Image Styles */
.dashboard-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.dashboard-img {
  max-width: 100%;
  border-radius: 0.75rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

.dashboard-container:hover .dashboard-img {
  transform: translateY(-5px) scale(1.01);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

/* Synthed AI Styles */
.synthed-ai-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.synthed-ai-card {
  background-color: rgba(24, 24, 36, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.synthed-ai-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.synthed-ai-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #f8fafc;
}

.synthed-ai-description {
  color: #94a3b8;
  font-size: 0.875rem;
  margin-top: 1.5rem;
}

.synthed-chart-container {
  position: relative;
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
}

.chart-alert {
  display: flex;
  align-items: center;
  background-color: rgba(231, 93, 93, 0.1);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
}

.chart-alert-icon {
  margin-right: 12px;
  color: #E75D5D;
}

.chart-alert-title {
  font-weight: 600;
  font-size: 0.875rem;
  color: #E75D5D;
}

.chart-alert-message {
  font-size: 0.75rem;
  color: #a1a1a6;
}

.chat-bubble {
  background-color: rgba(17, 24, 39, 0.8);
  border-radius: 12px;
  padding: 16px;
  color: #f8fafc;
  margin-bottom: 16px;
  max-width: 80%;
  font-size: 0.875rem;
}

.chat-ui {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  gap: 8px;
}

.chat-prompt {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #a1a1a6;
  margin-bottom: 16px;
  background-color: rgba(24, 24, 36, 0.5);
  border-radius: 8px;
  padding: 8px 12px;
}

.chat-prompt span:first-child {
  margin-right: 8px;
}

.ask-button {
  background-color: #0081F1;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-bottom: 16px;
}

.ask-button:hover {
  background-color: #0069c8;
}

/* Shadow-glow utility */
.shadow-glow {
  box-shadow: 0 0 15px rgba(56, 189, 248, 0.4);
}

/* Grid background pattern */
.grid-bg-pattern {
  background-color: rgba(9, 9, 11, 0.9);
  background-image: 
    linear-gradient(rgba(59, 130, 246, 0.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(59, 130, 246, 0.1) 1px, transparent 1px);
  background-size: 26px 26px;
  position: relative;
  color: transparent;
  -webkit-text-fill-color: initial;
}

.grid-bg-pattern::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 0%, rgba(9, 9, 11, 0.9) 80%);
  pointer-events: none;
  z-index: 1;
}

.grid-bg-pattern > * {
  position: relative;
  z-index: 2;
  color: inherit;
  -webkit-text-fill-color: inherit;
}

/* Ensure metallic text renders properly and doesn't leak into background */
.grid-bg-pattern .metallic-text {
  position: relative;
  z-index: 3;
  background-clip: text;
  -webkit-background-clip: text;
}

/* Fixed background polygon for CTA section */
.bg-gradient-polygon {
  clip-path: polygon(77.5% 40.13%, 90% 10%, 100% 50%, 95% 80%, 92% 85%, 75% 65%, 61.26% 54.7%, 50% 54.7%, 47.24% 65.81%, 50% 85%, 26.16% 73.91%, 0.1% 100%, 1% 40.13%, 20% 48.75%, 60% 0.25%, 67.5% 32.63%);
  background-image: linear-gradient(to right, rgba(14, 165, 233, 0.2), rgba(37, 99, 235, 0.2));
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.2;
  z-index: -1;
}

/* Blog Page Styles */
.blog-header {
  padding: 6rem 0 3rem;
  text-align: center;
  background: linear-gradient(to bottom, rgba(56,189,248,0.1) 0%, rgba(56,189,248,0) 100%);
}

.blog-title {
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(to bottom right, white, #71717a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.blog-subtitle {
  font-size: 1.25rem;
  color: #94a3b8;
  max-width: 800px;
  margin: 0 auto;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.featured-post {
  grid-column: 1 / -1;
  background: rgba(24, 24, 36, 0.7);
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.featured-post:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.featured-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.featured-content {
  padding: 2rem;
}

.featured-badge {
  display: inline-block;
  background: rgba(56, 189, 248, 0.1);
  color: #38bdf8;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.featured-title {
  font-size: 2rem;
  font-weight: bold;
  color: #f8fafc;
  margin-bottom: 1rem;
}

.featured-excerpt {
  color: #94a3b8;
  margin-bottom: 2rem;
}

.blog-card {
  background: rgba(24, 24, 36, 0.7);
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-date {
  color: #94a3b8;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.read-more {
  display: inline-flex;
  align-items: center;
  color: #38bdf8;
  font-size: 0.875rem;
  transition: color 0.2s;
}

.read-more:hover {
  color: #0ea5e9;
}

.read-more svg {
  margin-left: 0.5rem;
  transition: transform 0.2s;
}

.read-more:hover svg {
  transform: translateX(4px);
}

/* Categories Styles */
.categories {
  margin: 3rem 0;
}

.categories-heading {
  font-size: 1.5rem;
  color: #f8fafc;
  margin-bottom: 1.5rem;
}

.category-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.category-btn {
  background: rgba(24, 24, 36, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #94a3b8;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  transition: all 0.2s;
  cursor: pointer;
}

.category-btn:hover {
  background: rgba(56, 189, 248, 0.1);
  border-color: rgba(56, 189, 248, 0.5);
  color: #38bdf8;
}

.category-btn.active {
  background: rgba(56, 189, 248, 0.1);
  border-color: #38bdf8;
  color: #38bdf8;
}

/* Add these styles to select which version to keep */
/* Option 1: Keep only the top chat interface */
.synthed-ai-section:nth-of-type(2) {
  display: none !important; /* Hide the second instance */
}

/* OR Option 2: Keep only the bottom chat interface */
.synthed-ai-section:nth-of-type(1) {
  display: none !important; /* Hide the first instance */
}

/* Fix the "Powered by" section */
.synthed-ai-heading-section {
  margin: 3rem 0 1rem 0 !important; /* Add proper spacing */
  text-align: center;
}

/* Make feature sections more distinct */
#features, .features-section {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

/* Move hero content to chat empty space */
.chat-empty-space {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background: linear-gradient(to bottom, rgba(15, 23, 42, 0.5), rgba(12, 74, 110, 0.3));
  border-radius: 0 0 12px 12px;
  height: 100%;
  width: 100%;
  text-align: center;
}

/* Adjust headline for the new space */
.chat-empty-space .headline {
  font-size: 1.5rem; /* Smaller size to fit the chat area */
  margin-bottom: 0.75rem;
  max-width: 90%;
}

/* Adjust tagline for the new space */
.chat-empty-space .tagline {
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
  max-width: 90%;
}

/* Make CTA button more appropriate for this position */
.chat-empty-space .cta-button {
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
}

/* Hide the original hero section when displayed in chat */
.hero-section.moved-to-chat {
  display: none;
}

/* Additional responsive utilities */
.text-responsive {
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.text-heading-responsive {
  font-size: clamp(1.5rem, 4vw, 3rem);
}

/* Mobile touch-friendly adjustments */
@media (max-width: 640px) {
  /* Make all interactive elements easier to tap */
  .cta-button, 
  button,
  [role="button"],
  a,
  input[type="text"] {
    padding: 0.625rem 1rem;
    min-height: 44px;
    min-width: 44px;
  }
  
  /* Add more space between items in the mobile menu */
  .md\:hidden nav a {
    padding: 12px 0;
  }
  
  /* Prevent text from getting too small on tiny screens */
  .text-sm {
    font-size: 0.875rem !important;
  }
  
  /* Fix scrolling in chat interface */
  .max-h-\[350px\] {
    max-height: 70vh;
  }
  
  /* Improve readability */
  p {
    line-height: 1.5;
  }
  
  /* Adjust padding for mobile */
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  /* Better scroll handling on mobile */
  .overflow-y-auto {
    -webkit-overflow-scrolling: touch;
  }
}

/* Adjust card transitions for mobile */
@media (hover: none) {
  /* Disable hover effects on touch devices */
  .hover\:-translate-y-1:hover {
    transform: translateY(0);
  }
  
  /* Use active state instead for touch feedback */
  .hover\:-translate-y-1:active {
    transform: translateY(-2px);
    transition: transform 0.1s ease;
  }
}