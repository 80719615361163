/* SynthedAI Component Styles */

/* Container Styles */
.chat-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.chat-interface {
  position: relative;
  background: linear-gradient(to bottom, rgba(24, 24, 36, 0.7), rgba(15, 15, 20, 0.5));
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  height: 300px;
  display: flex;
  flex-direction: column;
}

/* Chat Header */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
}

.assistant-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.assistant-avatar {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: linear-gradient(135deg, #3b82f6, #6366f1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
}

.assistant-details h4 {
  margin: 0;
}

.assistant-details p {
  margin: 0;
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.6);
}

.status-indicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #a1a1aa;
  font-size: 0.875rem;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #10b981;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Chat Messages */
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 0;
  scrollbar-width: thin;
  scrollbar-color: #4b5563 #1f2937;
}

.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
  background-color: #1f2937;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #4b5563;
  border-radius: 3px;
}

/* Message Styling */
.message {
  display: flex;
  gap: 10px;
  margin-bottom: 1.5rem;
  max-width: 90%;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.ai-message {
  margin-right: auto;
}

.user-message {
  margin-left: auto;
  flex-direction: row-reverse;
}

.message-avatar {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background: linear-gradient(135deg, #3b82f6, #6366f1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  flex-shrink: 0;
}

.ai-message .message-avatar {
  background: linear-gradient(135deg, #3b82f6, #8b5cf6);
  box-shadow: 0 0 10px rgba(59, 130, 246, 0.3);
}

.user-message .message-avatar {
  background: linear-gradient(135deg, #10b981, #06b6d4);
  box-shadow: 0 0 10px rgba(16, 185, 129, 0.3);
}

.message-content {
  background-color: rgba(41, 44, 51, 0.6);
  border-radius: 12px;
  padding: 12px 16px;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #e2e8f0;
  border: 1px solid rgba(82, 82, 91, 0.3);
  backdrop-filter: blur(4px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.ai-message .message-content {
  border-top-left-radius: 4px;
}

.user-message .message-content {
  background-color: rgba(59, 130, 246, 0.15);
  border: 1px solid rgba(59, 130, 246, 0.3);
  border-top-right-radius: 4px;
}

.message-content p {
  margin: 0 0 0.75rem 0;
}

.message-content p:last-child {
  margin-bottom: 0;
}

/* Alert Component */
.forecast-alert {
  display: flex;
  gap: 12px;
  padding: 12px;
  border-bottom: 1px solid rgba(55, 65, 81, 0.5);
  align-items: flex-start;
}

.alert-icon {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-top: 2px;
}

.alert-content h5 {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
}

.alert-content p {
  font-size: 13px;
  opacity: 0.8;
}

.forecast-alert.critical {
  background-color: rgba(239, 68, 68, 0.1);
}
.forecast-alert.critical .alert-icon {
  color: #ef4444;
}
.forecast-alert.critical h5 {
  color: #ef4444;
}

.forecast-alert.warning {
  background-color: rgba(245, 158, 11, 0.1);
}
.forecast-alert.warning .alert-icon {
  color: #f59e0b;
}
.forecast-alert.warning h5 {
  color: #f59e0b;
}

.forecast-alert.positive {
  background-color: rgba(34, 197, 94, 0.1);
}
.forecast-alert.positive .alert-icon {
  color: #22c55e;
}
.forecast-alert.positive h5 {
  color: #22c55e;
}

.forecast-alert.info {
  background-color: rgba(59, 130, 246, 0.1);
}
.forecast-alert.info .alert-icon {
  color: #3b82f6;
}
.forecast-alert.info h5 {
  color: #3b82f6;
}

/* Chart Component */
.forecast-chart {
  margin-top: 1rem;
  background-color: rgba(17, 24, 39, 0.7);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgba(55, 65, 81, 0.5);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid rgba(55, 65, 81, 0.5);
  font-size: 14px;
  font-weight: 500;
  color: #d1d5db;
}

.chart-visualization {
  position: relative;
  height: 180px;
  padding: 20px 12px 0;
}

.chart-grid {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 20px;
}

.grid-line {
  width: 100%;
  height: 1px;
  background-color: rgba(55, 65, 81, 0.3);
}

.chart-svg {
  position: relative;
  z-index: 10;
  height: 100%;
  width: 100%;
}

.chart-line {
  fill: none;
  stroke: #3b82f6;
  stroke-width: 2;
}

.chart-area {
  fill: url(#chart-gradient);
}

.chart-point {
  fill: white;
  stroke: #3b82f6;
  stroke-width: 2;
}

.may-point {
  animation: pulse-point 2s infinite;
}

@keyframes pulse-point {
  0% {
    r: 3;
    stroke-width: 2;
  }
  50% {
    r: 4;
    stroke-width: 1;
  }
  100% {
    r: 3;
    stroke-width: 2;
  }
}

.chart-labels {
  display: flex;
  justify-content: space-between;
  padding: 0 12px 12px;
  color: #9ca3af;
  font-size: 12px;
}

.chart-labels .highlight {
  color: #60a5fa;
  font-weight: 500;
}

/* Typing Indicator */
.typing {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px;
}

.typing span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #94a3b8;
  animation: typing 1.4s infinite ease-in-out;
}

.typing span:nth-child(1) {
  animation-delay: 0s;
}

.typing span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0%, 60%, 100% {
    transform: translateY(0);
    opacity: 0.6;
  }
  30% {
    transform: translateY(-6px);
    opacity: 1;
  }
}

/* Chat Input */
.chat-input {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
}

.chat-input input {
  flex: 1;
}

.chat-input button {
  flex-shrink: 0;
}

/* Feature Cards */
.synthed-features {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@media (max-width: 640px) {
  .synthed-features {
    grid-template-columns: 1fr;
  }
}

.feature-card {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  padding: 1.25rem;
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 0.75rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.feature-icon {
  width: 42px;
  height: 42px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.feature-icon.cyan {
  background-color: rgba(8, 145, 178, 0.2);
}

.feature-icon.purple {
  background-color: rgba(124, 58, 237, 0.2);
}

.feature-icon svg {
  width: 24px;
  height: 24px;
  stroke-width: 2px;
}

.feature-icon.cyan svg {
  color: rgba(8, 145, 178, 0.9);
}

.feature-icon.purple svg {
  color: rgba(124, 58, 237, 0.9);
}

.feature-text {
  flex: 1;
}

.feature-text h4 {
  margin: 0 0 0.5rem 0;
}

.feature-text p {
  margin: 0;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
}

/* Thinking animation */
.thinking-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px 0;
}

.thinking-animation .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #a1a1aa;
  opacity: 0.6;
  animation: thinking-dot 1.4s infinite ease-in-out both;
}

.thinking-animation .dot:nth-child(1) {
  animation-delay: -0.32s;
}

.thinking-animation .dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes thinking-dot {
  0%, 80%, 100% {
    transform: scale(0.6);
  }
  40% {
    transform: scale(1);
  }
}

/* Border utilities */
.border-l-3 {
  border-left-width: 3px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .message {
    max-width: 95%;
  }
  
  .chart-visualization {
    height: 140px;
  }
}

.bg-zinc-900\/70 {
  background: linear-gradient(180deg, rgba(15, 23, 42, 0.75) 0%, rgba(3, 7, 18, 0.85) 100%);
  backdrop-filter: blur(12px);
}

.synthed-ai-section .container {
  position: relative;
}

.synthed-ai-section .container > div {
  box-shadow: 0 0 40px rgba(59, 130, 246, 0.05);
  transition: box-shadow 0.3s ease;
}

.synthed-ai-section .container > div:hover {
  box-shadow: 0 0 50px rgba(59, 130, 246, 0.08);
}

/* Enhance the overall sidebar design to be less segmented */
.synthed-ai-section .bg-gradient-to-b.from-zinc-900\/50.to-zinc-950\/30 {
  background: linear-gradient(180deg, rgba(15, 23, 42, 0.6) 0%, rgba(3, 7, 18, 0.8) 100%);
  box-shadow: inset -1px 0 0 rgba(59, 130, 246, 0.1);
}

/* Timeline animations and transitions */
.synthed-ai-section .before\:bg-gradient-to-b {
  animation: pulseGradient 3s ease infinite alternate;
}

@keyframes pulseGradient {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.7;
  }
}

.synthed-ai-section .rounded-full.bg-blue-500,
.synthed-ai-section .rounded-full.bg-green-500,
.synthed-ai-section .rounded-full.bg-purple-500 {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.synthed-ai-section .mb-3:hover .rounded-full.bg-blue-500,
.synthed-ai-section .mb-3:hover .rounded-full.bg-green-500,
.synthed-ai-section .relative:hover .rounded-full.bg-purple-500 {
  transform: scale(1.2);
  box-shadow: 0 0 12px rgba(100, 150, 255, 0.8);
}

/* Improve alert sections */
.synthed-ai-section .border-l-3.border-red-500 {
  background: linear-gradient(90deg, rgba(220, 38, 38, 0.15) 0%, rgba(30, 41, 59, 0.05) 100%);
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(185, 28, 28, 0.1);
}

.synthed-ai-section .border-l-3.border-blue-500 {
  background: linear-gradient(90deg, rgba(59, 130, 246, 0.15) 0%, rgba(30, 41, 59, 0.05) 100%);
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(37, 99, 235, 0.1);
}

.synthed-ai-section .border-l-3:hover {
  transform: translateX(3px);
  box-shadow: 0 4px 12px rgba(15, 23, 42, 0.2);
}

/* Unify smart recommendations */
.synthed-ai-section .space-y-3 .flex.gap-2 {
  position: relative;
  padding-left: 20px;
  padding-bottom: 10px;
}

.synthed-ai-section .space-y-3 .flex.gap-2:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 3px;
  top: 8px;
  bottom: 0;
  width: 1px;
  background: linear-gradient(to bottom, rgba(74, 222, 128, 0.7), rgba(74, 222, 128, 0.1));
}

.synthed-ai-section .w-1\.5.h-1\.5.rounded-full.bg-green-500 {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 6px rgba(74, 222, 128, 0.5);
}

/* Add drag and drop styles */
[draggable] {
  user-select: none;
  -webkit-user-drag: element;
}

[draggable]:active {
  opacity: 0.7;
}

/* Prevent text selection during drag */
.recommendation-card,
.insight-card,
.transaction-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hover effects for recommendation cards */
.recommendation-card:hover .drag-indicator {
  display: flex;
  align-items: center;
}

/* Priority drop area animations */
.priority-drop-area {
  transition: all 0.3s ease;
  animation: pulseBorder 3s infinite alternate;
}

@keyframes pulseBorder {
  0% {
    border-color: rgba(63, 63, 70, 0.5);
  }
  100% {
    border-color: rgba(59, 130, 246, 0.3);
  }
}

/* Priority item hover effects */
.priority-item {
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.priority-item:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.priority-item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.priority-item:hover::after {
  transform: translateX(100%);
}

/* Click animation for priority items */
.priority-item:active {
  transform: translateY(0px) scale(0.98);
  transition: transform 0.1s;
}

/* Subtle shine effect on hover */
.priority-item::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, transparent 60%);
  opacity: 0;
  transform: rotate(30deg);
  transition: opacity 0.5s ease, transform 1s ease;
  pointer-events: none;
}

.priority-item:hover::before {
  opacity: 1;
  transform: rotate(0deg);
}

/* Progress bar animation */
.priority-item .bg-gradient-to-r {
  animation: shimmer 2s infinite;
  background-size: 200% 100%;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

/* Button hover effects */
.priority-item button {
  transform: translateZ(0);
  transition: all 0.2s ease-out;
}

.priority-item button:hover {
  transform: translateZ(0) scale(1.2);
}

/* View button animation */
.priority-item span:last-child {
  position: relative;
  transition: all 0.2s ease;
}

.priority-item:hover span:last-child {
  transform: translateX(3px);
  color: #e2e8f0;
}

/* Add consistent styling for both top and bottom sections */
.synthed-ai-section .container {
  position: relative;
}

.synthed-ai-section .container > div {
  box-shadow: 0 0 40px rgba(59, 130, 246, 0.05);
  transition: box-shadow 0.3s ease;
}

.synthed-ai-section .container > div:hover {
  box-shadow: 0 0 50px rgba(59, 130, 246, 0.08);
}

/* Blue glow effect for feature cards when hovering */
.feature-card {
  transition: all 0.3s ease;
  position: relative;
}

.feature-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: inherit;
  opacity: 0;
  box-shadow: 0 0 30px rgba(59, 130, 246, 0.15);
  transition: opacity 0.3s ease;
}

.feature-card:hover::after {
  opacity: 1;
}

/* Add subtle background gradient to match SynthedAI section */
#features {
  background: linear-gradient(180deg, rgba(17, 24, 39, 0.95) 0%, rgba(9, 9, 11, 1) 100%);
}

/* Match card styles between sections */
.feature-card {
  background: linear-gradient(
    135deg, 
    rgba(30, 41, 59, 0.3) 0%, 
    rgba(15, 23, 42, 0.3) 100%
  );
  border: 1px solid rgba(59, 130, 246, 0.08);
}

/* Consistent blue accent colors for buttons and interactive elements */
.blue-accent {
  color: #3b82f6;
}

.blue-accent-bg {
  background-color: rgba(59, 130, 246, 0.1);
  border: 1px solid rgba(59, 130, 246, 0.2);
}

/* Pulsing animation for blue elements */
@keyframes pulse-blue {
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
}

.pulse-blue {
  animation: pulse-blue 2s infinite;
} 